
import { useStore } from 'vuex'
import { computed, ref, defineComponent } from 'vue'
import TableCustomers from './TableCustomers.vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import * as actions from '@/store/actions.type'
import { ICustomersSearchMapping } from '@/types/interfaces/ICustomersSearchMapping'

export default defineComponent({
  name: 'Customers',
  components: { TableCustomers, SyncOutlined },
  setup() {
    const store = useStore()
    const isCustomersLoading = computed(() => store.state.customers.customersLoading)
    const customersSearchList = computed(() => {
      return store.state.customers.customersSearchList
        .map((cust: ICustomersSearchMapping) => {
          return {
            value: `${cust.displayName} (${cust.cid})`,
            id: cust.id,
          }
        })
        .sort((a: any, b: any) => {
          return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1
        })
    })
    const customersExists = computed(() => store.getters['customers/customersExists'])
    const customerSearchValue = ref()
    const dropdownOptions = ref([])
    const filterOptions = ref([])
    const searchLoading = ref(false)
    const reloadIconType = ref(false)

    const handleDropdownSearch = (searchText: string) => {
      filterDropdownOptions(searchText)
    }

    const handleSearchSelect = (val: string) => {
      filterDropdownOptions(val)
      handleSearchEnter(val)
    }

    const filterDropdownOptions = (text: string) => {
      const filteredList = customersSearchList.value.filter((rec: any) => {
        return rec.value.toLowerCase().includes(text?.toLowerCase())
      })
      dropdownOptions.value = !text ? customersSearchList.value : filteredList
    }

    const handleSearchEnter = (value: string) => {
      searchLoading.value = true
      if (value) {
        filterOptions.value = dropdownOptions.value
      }

      customerSearchValue.value = value
      searchLoading.value = false
    }

    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(`customers/${actions.CustomersAction.FETCH_CUSTOMERS_ON_LOAD}`)
      reloadIconType.value = false
    }

    return {
      isCustomersLoading,
      customersExists,
      customerSearchValue,
      customersSearchList,
      handleDropdownSearch,
      dropdownOptions,
      handleSearchSelect,
      handleSearchEnter,
      searchLoading,
      reloadIconType,
      handleDataReload,
      filterOptions,
    }
  },
})
