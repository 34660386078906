<template>
  <div class="mb-3">
    <a-alert
      message="Not all results have been listed"
      :description="alertMessage"
      type="warning"
      show-icon
      :closable="false"
      v-if="alertMessage"
      @close="() => (alertMessage = '')"
    />
  </div>
  <div class="table-responsive">
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :bordered="true"
      :row-key="(rec) => rec.id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handleChange"
      :scroll="{ x: true }"
    >
      <template #photo="{text}">
        <div class="d-flex flex-wrap align-items-center justify-content-center">
          <div class="flex-shrink-0">
            <a-avatar
              shape="square"
              size="default"
              style="color: #595c97; background-color: #f2f4f8;"
              :src="text"
            >
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </div>
        </div>
      </template>
      <template #customerType="{text}">
        <span class="align-middle text-primary text-uppercase font-size-12 badge badge-light">
          {{ text || 'N/A' }}
        </span>
      </template>
      <template #actions="{record}">
        <div>
          <ButtonGroupActions
            :button-list="actionButtonList"
            @handleViewClick="handleViewClick(record)"
            @handleEditClick="handleEditClick(record)"
            @handleDeleteClick="handleDeleteClick(record)"
          />
        </div>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, defineComponent, PropType, reactive, ref, watch } from 'vue'
import * as actions from '@/store/actions.type'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { CustomersDocument } from '@/types/firebaseCollectionContracts/CustomersDocument'
import { UserOutlined } from '@ant-design/icons-vue'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'

const columns = [
  {
    title: '',
    dataIndex: 'doc.photoUrl',
    key: 'photoUrl',
    slots: { customRender: 'photo' },
  },
  {
    title: 'Customer ID',
    dataIndex: 'doc.cid',
    key: 'cid',
  },
  {
    title: 'Type',
    dataIndex: 'doc.customerType',
    key: 'customerType',
    slots: { customRender: 'customerType' },
  },
  {
    title: 'Name',
    dataIndex: 'doc.displayName',
    key: 'displayName',
  },
  {
    title: 'Company Name',
    dataIndex: 'doc.companyName',
    key: 'companyName',
  },
  {
    title: 'Email',
    dataIndex: 'doc.email',
    key: 'email',
    ellipsis: true,
  },
  {
    title: 'Mobile',
    dataIndex: 'doc.mobile',
    key: 'mobile',
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'CustomersTable',
  components: { UserOutlined, ButtonGroupActions },
  props: {
    filterName: {
      type: String,
      default: (): String => {
        return ''
      },
    },
    filterOptions: {
      type: Object as PropType<Array<any>>,
      required: true,
    },
    totalRecords: {
      type: Number,
      default: (): Number => 0,
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const tableLoading = ref(false)
    const filterOptions = computed(() => props.filterOptions)
    const alertMessage = ref('')
    const totalTableRecords = computed(() => {
      return props.totalRecords
    })
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }
    const actionButtonList = [ActionButtonList.VIEW, ActionButtonList.EDIT, ActionButtonList.DELETE]

    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: totalTableRecords,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })
    const customerList = computed<IAppDocument<CustomersDocument>[]>(
      () => store.getters['customers/customerList'] as IAppDocument<CustomersDocument>[],
    )

    const dataSource = computed(() => {
      if (props.filterName) {
        // Else if its just a filter name, then find records matching name or cid.

        return customerList.value.filter((rec) => {
          return (
            rec.doc.displayName.toLowerCase().includes(props.filterName?.toLowerCase()) ||
            rec.doc.cid.toLowerCase().includes(props.filterName?.toLowerCase()) ||
            (props.filterName?.toLowerCase().includes(rec.doc.displayName.toLowerCase()) &&
              props.filterName?.toLowerCase().includes(rec.doc.cid.toLowerCase()))
          )
        })
      }
      return customerList.value
    })

    const handleViewClick = (record: IAppDocument<CustomersDocument>) => {
      router.push({ name: 'customerProfile', params: { id: record.id } })
    }

    const handleChange = async (page: any) => {
      tableLoading.value = true
      const required: number = page.current * page.pageSize
      if (required > customerList.value.length) {
        const fetchSize = required - customerList.value.length
        await store.dispatch(`customers/${actions.CustomersAction.FETCH_CUSTOMERS_FROM_SERVER}`, {
          fetchSize,
        })
      }
      tableLoading.value = false
    }

    const handleDataSearch = async () => {
      tableLoading.value = true
      if (filterOptions?.value.length) {
        const searchList = filterOptions.value
          .map((opt) => opt.id)
          .filter((id) => !store.state.customers.customersMap.has(id))
        if (searchList.length > 100) {
          alertMessage.value =
            'The search returned more than the maximum number of search rows (100) from the server. Please refine your search criteria.'
        } else {
          alertMessage.value = ''
          await store.dispatch(`customers/${actions.CustomersAction.FETCH_CUSTOMERS_BY_IDLIST}`, {
            idList: searchList,
          })
        }
      }
      tableLoading.value = false
    }

    const handleEditClick = (record: IAppDocument<any>) => {
      router.push({ name: 'editCustomer', params: { id: record.id } })
    }

    const handleDeleteClick = async (record: IAppDocument<any>) => {
      tableLoading.value = true
      const docOperation: DocumentOperation<null> = {
        action: DocumentActions.DELETE,
        id: record.id,
        payload: null,
        audit: null,
      }
      await store.dispatch(
        `customers/${actions.CustomersAction.SET_OR_DELETE_CUSTOMER}`,
        docOperation,
      )
      tableLoading.value = false
    }

    watch(filterOptions, async () => {
      await handleDataSearch()
    })

    return {
      columns,
      dataSource,
      handleViewClick,
      pagination,
      handleChange,
      tableLoading,
      alertMessage,
      actionButtonList,
      handleEditClick,
      handleDeleteClick,
    }
  },
})
</script>

<style lang="scss">
.ant-pagination-item,
.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  display: none !important;
}
</style>
