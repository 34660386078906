<template>
  <div>
    <div class="d-flex flex-wrap border-bottom mb-3">
      <div class="mt-4 mr-auto">
        <h4>Customers</h4>
      </div>
      <router-link
        :to="{ name: 'newCustomer' }"
        class="btn btn-success btn-with-addon text-nowrap my-3 mr-3"
      >
        <span class="btn-addon">
          <i class="btn-addon-icon fe fe fe-user-plus"></i>
        </span>
        New
      </router-link>
      <router-link
        :to="{ name: 'newCustomerIssue' }"
        class="btn btn-success btn-with-addon text-nowrap my-3 mr-3"
      >
        <span class="btn-addon">
          <i class="btn-addon-icon fe fe fe-plus"></i>
        </span>
        Issue
      </router-link>
      <div class="dropdown my-3 mr-3">
        <a-dropdown placement="bottomLeft" :trigger="['click']">
          <a class="btn btn-light text-blue dropdown-toggle" href="javascript: void(0);">Actions</a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <router-link
                  :to="{
                    name: 'importFile',
                    query: { entity: 'customers' },
                  }"
                >
                  Import
                </router-link>
                <!-- <a href="javascript:;">Import</a> -->
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">Export</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <a-tooltip title="Refresh">
          <button type="button" class="btn btn-light" @click="handleDataReload">
            <SyncOutlined :spin="reloadIconType" />
          </button>
        </a-tooltip>
      </div>
    </div>
    <div>
      <a-skeleton :loading="isCustomersLoading" active>
        <div class="text-center my-5" v-if="!customersExists">
          <h3>Business is no fun without people.</h3>
          <p class="text-muted">
            Create and manage your contacts, all in one place.
          </p>
          <!-- <template> -->
          <div>
            <a-empty :description="false" />
          </div>
          <!-- </template> -->
          <router-link :to="{ name: 'newCustomer' }" class="btn btn-success my-2 text-uppercase"
            >Create New Customer
          </router-link>
        </div>
        <div v-else>
          <div>
            <div class="mb-4">
              <a-auto-complete
                :allow-clear="false"
                style="width: 50%"
                :options="dropdownOptions"
                :backfill="false"
                :auto-focus="true"
                :default-active-first-option="false"
                @search="handleDropdownSearch"
                @select="handleSearchSelect"
              >
                <a-input-search
                  size="large"
                  placeholder="Search Customers.."
                  enter-button
                  :loading="searchLoading"
                  @search="handleSearchEnter"
                ></a-input-search>
              </a-auto-complete>
            </div>
            <TableCustomers
              :filter-name="customerSearchValue"
              :filter-options="filterOptions"
              :total-records="dropdownOptions?.length || customersSearchList?.length"
            />
          </div>
        </div>
      </a-skeleton>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { computed, ref, defineComponent } from 'vue'
import TableCustomers from './TableCustomers.vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import * as actions from '@/store/actions.type'
import { ICustomersSearchMapping } from '@/types/interfaces/ICustomersSearchMapping'

export default defineComponent({
  name: 'Customers',
  components: { TableCustomers, SyncOutlined },
  setup() {
    const store = useStore()
    const isCustomersLoading = computed(() => store.state.customers.customersLoading)
    const customersSearchList = computed(() => {
      return store.state.customers.customersSearchList
        .map((cust: ICustomersSearchMapping) => {
          return {
            value: `${cust.displayName} (${cust.cid})`,
            id: cust.id,
          }
        })
        .sort((a: any, b: any) => {
          return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1
        })
    })
    const customersExists = computed(() => store.getters['customers/customersExists'])
    const customerSearchValue = ref()
    const dropdownOptions = ref([])
    const filterOptions = ref([])
    const searchLoading = ref(false)
    const reloadIconType = ref(false)

    const handleDropdownSearch = (searchText: string) => {
      filterDropdownOptions(searchText)
    }

    const handleSearchSelect = (val: string) => {
      filterDropdownOptions(val)
      handleSearchEnter(val)
    }

    const filterDropdownOptions = (text: string) => {
      const filteredList = customersSearchList.value.filter((rec: any) => {
        return rec.value.toLowerCase().includes(text?.toLowerCase())
      })
      dropdownOptions.value = !text ? customersSearchList.value : filteredList
    }

    const handleSearchEnter = (value: string) => {
      searchLoading.value = true
      if (value) {
        filterOptions.value = dropdownOptions.value
      }

      customerSearchValue.value = value
      searchLoading.value = false
    }

    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(`customers/${actions.CustomersAction.FETCH_CUSTOMERS_ON_LOAD}`)
      reloadIconType.value = false
    }

    return {
      isCustomersLoading,
      customersExists,
      customerSearchValue,
      customersSearchList,
      handleDropdownSearch,
      dropdownOptions,
      handleSearchSelect,
      handleSearchEnter,
      searchLoading,
      reloadIconType,
      handleDataReload,
      filterOptions,
    }
  },
})
</script>
